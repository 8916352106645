import { List } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import React, { ReactElement, useCallback } from 'react';

import { selectedBoxSelector } from 'entities/box/model';

import { putRuleAction, rulesDataSelector, rulesIsLoadingSelector } from 'entities/rule/model';
import { RuleCard } from 'entities/rule/ui';

import { useSmartPolling } from '../lib/hooks/useSmartPolling';

function RulesList(): ReactElement {
  const dispatch = useDispatch();
  const box = useSelector(selectedBoxSelector);

  const rules = useSelector(rulesDataSelector);
  const rulesIsLoading = useSelector(rulesIsLoadingSelector);

  useSmartPolling();

  const onChangeStatus = useCallback(
    (name: string, status: boolean): void => {
      if (box) {
        dispatch(putRuleAction.start({ boxId: box.id, ruleName: name, enable: status }));
      }
    },
    [box, dispatch],
  );

  return (
    <List
      loading={rulesIsLoading}
      grid={{ gutter: 12 }}
      dataSource={rules}
      renderItem={(rule) => (
        <List.Item>
          <RuleCard rule={rule} onChangeStatus={onChangeStatus} />
        </List.Item>
      )}
    />
  );
}

export default React.memo(RulesList);
