import { useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { NavItem } from '../../model/types';

type UseSelectedSidebarItemsHook = {
  selectedItemKeys: string[];
};

export const useSelectedSidebarItems = (navItems: NavItem[]): UseSelectedSidebarItemsHook => {
  const { pathname } = useLocation();
  const { tag: subNavItemKey } = useParams();

  const selectedItemKeys: string[] = useMemo(() => {
    const keys = [];
    const navItemKey = navItems.find((item) => item.path === pathname)?.key;

    if (navItemKey) keys.push(navItemKey);
    if (subNavItemKey) keys.push(subNavItemKey);

    return keys;
  }, [navItems, pathname, subNavItemKey]);

  return { selectedItemKeys };
};
