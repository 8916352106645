import { CallEffect, PutEffect, all, call, put, takeEvery, throttle } from 'redux-saga/effects';

import { putItemAction } from './actions';
import { PutItemSuccessPayload } from './types';
import { putItem } from '../api/requests';

export function* putItemSaga(
  action: ReturnType<typeof putItemAction.start>,
): Generator<PutEffect | CallEffect, void, void> {
  const { boxId, itemId, command } = action.payload;

  try {
    yield call(putItem, boxId, itemId, command);
  } catch (error: unknown) {
    yield put(putItemAction.failure());
  }
}

// Separate saga to update the Store. Because BE doesn't return response, we should keep the Store up-to-date
// Once polling and / or websockets grab a new data - the store would be updated anyway
export function* putItemStoreUpdateSaga(
  action: ReturnType<typeof putItemAction.start>,
): Generator<PutEffect | CallEffect, void, PutItemSuccessPayload> {
  const { itemId, command } = action.payload;

  // it is also could be called "finally", because Store is updating anyway
  yield put(putItemAction.success({ itemId, value: command }));
}

export function* itemSagas(): Generator {
  yield all([
    yield throttle(500, putItemAction.start, putItemSaga),
    yield takeEvery(putItemAction.start, putItemStoreUpdateSaga),
  ]);
}
