import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { checkEpochTimeStampOutdated } from 'shared/lib';
import { POLL_INTERVAL } from 'shared/consts';
import { selectedBoxSelector } from 'entities/box/model';
import { pollDevicesAction } from 'entities/device/model';
import { itemsLastPatchTimestampSelector } from 'entities/item/model';

const itemPatchPollInterval = 300;

// Smart Polling basically means - pause (for a while) polling BE after item state Patch Request sent
export const useSmartPolling = (): void => {
  const dispatch = useDispatch();
  const box = useSelector(selectedBoxSelector);

  const itemsLastPatchTimestamp = useSelector(itemsLastPatchTimestampSelector);

  const isBackendPollingActive = useRef(true);

  useEffect(() => {
    if (box?.id) {
      dispatch(pollDevicesAction.start({ boxId: box.id }));
    }
  }, [dispatch, box]);

  useEffect(() => {
    if (!box) {
      return undefined;
    }

    const timer = setInterval(() => {
      // If no Item "patches", should continue BE polling
      if (!itemsLastPatchTimestamp) {
        return;
      }

      const itemPatchOutdated = checkEpochTimeStampOutdated(itemsLastPatchTimestamp, POLL_INTERVAL);

      // If patch outdated AND no active BE polling, start the BE polling
      if (itemPatchOutdated && !isBackendPollingActive.current) {
        dispatch(pollDevicesAction.start({ boxId: box.id }));
        isBackendPollingActive.current = true;
        return;
      }

      // If patch is fresh AND active BE polling, stop the BE polling
      if (!itemPatchOutdated && isBackendPollingActive.current) {
        dispatch(pollDevicesAction.stop());
        isBackendPollingActive.current = false;
      }

      // All other cases - BE polling should happen
    }, itemPatchPollInterval);

    return () => {
      clearInterval(timer);
      dispatch(pollDevicesAction.stop());
      isBackendPollingActive.current = false;
    };
  }, [box, itemsLastPatchTimestamp, dispatch]);
};
