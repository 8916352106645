import { createReducer } from '@reduxjs/toolkit';

import { IDeviceState } from './types';
import { getDevicesAction } from './actions';
import { mapDevicesDTO } from '../lib/helpers/mapDevicesDTO';

export const deviceInitialState: IDeviceState = {
  devices: {
    isLoading: false,
    data: [],
  },
};

export const deviceReducer = createReducer(deviceInitialState, (builder) => {
  builder
    .addCase(getDevicesAction.start, (state) => ({
      ...state,
      devices: {
        ...state.devices,
        isLoading: true,
      },
    }))
    .addCase(getDevicesAction.success, (state, action) => ({
      ...state,
      devices: {
        ...state.devices,
        data: mapDevicesDTO(action.payload),
        isLoading: false,
      },
    }))
    .addCase(getDevicesAction.failure, (state) => ({
      ...state,
      devices: {
        ...state.devices,
        isLoading: false,
      },
    }));
});
