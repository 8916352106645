import React, { ReactElement, useState } from 'react';
import { Card, Switch } from 'antd';
import { SwitchChangeEventHandler } from 'antd/es/switch';
import classNames from 'classnames';

import { Rule } from 'entities/rule/model';

import cardStyles from 'styles/ant-custom/card.module.scss';
import styles from './RuleCard.module.scss';

type RuleCardProps = {
  rule: Rule;
  onChangeStatus: (name: Rule['name'], enabled: Rule['enabled']) => void;
};

const RuleCard = React.memo((props: RuleCardProps): ReactElement => {
  const { rule, onChangeStatus } = props;

  const [checked, setChecked] = useState(rule.enabled);

  const onChangeWrapper: SwitchChangeEventHandler = (value): void => {
    onChangeStatus(rule.name, value);
    setChecked(value);
  };

  return (
    <Card className={classNames(cardStyles.zeroPadding, styles.rulesItem)}>
      <div className={styles.content}>
        <span className={styles.name}>{rule.name}</span>
        <Switch checked={checked} onChange={onChangeWrapper} />
      </div>
    </Card>
  );
});

export { RuleCard };
