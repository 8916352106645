import { ThemeConfig } from 'antd';

import styles from './UIConfigProvider.module.scss';

const brandColors: ThemeConfig['token'] = {
  colorPrimary: styles.colorPrimary,
  colorPrimaryBg: styles.colorPrimaryBg,
  colorPrimaryBgHover: styles.colorPrimaryBgHover,
  colorPrimaryBorder: styles.colorPrimaryBorder,
  colorPrimaryBorderHover: styles.colorPrimaryBorderHover,
  colorPrimaryHover: styles.colorPrimaryHover,
  colorPrimaryActive: styles.colorPrimaryActive,
  colorPrimaryTextHover: styles.colorPrimaryTextHover,
  colorPrimaryText: styles.colorPrimaryText,
  colorPrimaryTextActive: styles.colorPrimaryTextActive,
};

const successColors: ThemeConfig['token'] = {
  colorSuccess: styles.colorSuccess,
  colorSuccessBg: styles.colorSuccessBg,
  colorSuccessBgHover: styles.colorSuccessBgHover,
  colorSuccessBorder: styles.colorSuccessBorder,
  colorSuccessBorderHover: styles.colorSuccessBorderHover,
  colorSuccessHover: styles.colorSuccessHover,
  colorSuccessActive: styles.colorSuccessActive,
  colorSuccessTextHover: styles.colorSuccessTextHover,
  colorSuccessText: styles.colorSuccessText,
  colorSuccessTextActive: styles.colorSuccessTextActive,
};

const warningColors: ThemeConfig['token'] = {
  colorWarning: styles.colorWarning,
  colorWarningBg: styles.colorWarningBg,
  colorWarningBgHover: styles.colorWarningBgHover,
  colorWarningBorder: styles.colorWarningBorder,
  colorWarningBorderHover: styles.colorWarningBorderHover,
  colorWarningHover: styles.colorWarningHover,
  colorWarningActive: styles.colorWarningActive,
  colorWarningTextHover: styles.colorWarningTextHover,
  colorWarningText: styles.colorWarningText,
  colorWarningTextActive: styles.colorWarningTextActive,
};

const errorColors: ThemeConfig['token'] = {
  colorError: styles.colorError,
  colorErrorBg: styles.colorErrorBg,
  colorErrorBgHover: styles.colorErrorBgHover,
  colorErrorBorder: styles.colorErrorBorder,
  colorErrorBorderHover: styles.colorErrorBorderHover,
  colorErrorHover: styles.colorErrorHover,
  colorErrorActive: styles.colorErrorActive,
  colorErrorTextHover: styles.colorErrorTextHover,
  colorErrorText: styles.colorErrorText,
  colorErrorTextActive: styles.colorErrorTextActive,
};

const infoColors: ThemeConfig['token'] = {
  colorInfo: styles.colorInfo,
  colorInfoBg: styles.colorInfoBg,
  colorInfoBgHover: styles.colorInfoBgHover,
  colorInfoBorder: styles.colorInfoBorder,
  colorInfoBorderHover: styles.colorInfoBorderHover,
  colorInfoHover: styles.colorInfoHover,
  colorInfoActive: styles.colorInfoActive,
  colorInfoTextHover: styles.colorInfoTextHover,
  colorInfoText: styles.colorInfoText,
  colorInfoTextActive: styles.colorInfoTextActive,
};

const neutralColors: ThemeConfig['token'] = {
  colorText: styles.colorText,
  colorTextSecondary: styles.colorTextSecondary,
  colorTextTertiary: styles.colorTextTertiary,
  colorTextQuaternary: styles.colorTextQuaternary,
  colorBorder: styles.colorBorder,
  colorBorderSecondary: styles.colorBorderSecondary,
  colorFill: styles.colorFill,
  colorFillSecondary: styles.colorFillSecondary,
  colorFillTertiary: styles.colorFillTertiary,
  colorFillQuaternary: styles.colorFillQuaternary,
  colorBgContainer: styles.colorBgContainer,
  colorBgElevated: styles.colorBgElevated,
  colorBgLayout: styles.colorBgLayout,
  colorBgSpotlight: styles.colorBgSpotlight,
  colorBgMask: styles.colorBgMask,
};

const typography: ThemeConfig['token'] = {
  fontSize: parseInt(styles.fontSize, 10),
  fontSizeHeading1: parseInt(styles.fontSizeHeading1, 10),
  fontSizeHeading2: parseInt(styles.fontSizeHeading2, 10),
  fontSizeHeading3: parseInt(styles.fontSizeHeading3, 10),
  fontSizeHeading4: parseInt(styles.fontSizeHeading4, 10),
  fontSizeHeading5: parseInt(styles.fontSizeHeading5, 10),
  lineHeightHeading1: parseInt(styles.lineHeightHeading1, 10),
  lineHeightHeading2: parseInt(styles.lineHeightHeading2, 10),
  lineHeightHeading3: parseInt(styles.lineHeightHeading3, 10),
  lineHeightHeading4: parseInt(styles.lineHeightHeading4, 10),
  lineHeightHeading5: parseInt(styles.lineHeightHeading5, 10),
};

const DEFAULT_THEME: ThemeConfig = {
  token: {
    ...brandColors,
    ...successColors,
    ...warningColors,
    ...errorColors,
    ...infoColors,
    ...neutralColors,
    ...typography,
  },
};

export default DEFAULT_THEME;
