import React, { memo, ReactElement } from 'react';
import { Button } from 'antd';
import cn from 'classnames';

import { ReactComponent as MenuFold } from 'assets/menu_fold.svg';
import { ReactComponent as MenuUnfold } from 'assets/menu_unfold.svg';
import { Logo } from 'shared/ui';

import styles from './NavigationSidebarHeader.module.scss';

type Props = {
  collapsed: boolean;
  handleToggleBtnClick: () => void;
};

const NavigationSidebarHeader = ({ collapsed, handleToggleBtnClick }: Props): ReactElement => {
  return (
    <div className={cn(styles.sidebarHeader, collapsed && styles.navCollapsed)}>
      {!collapsed ? <Logo /> : null}
      <Button type="default" size="middle" onClick={handleToggleBtnClick} shape="circle" className={styles.navToggle}>
        {collapsed ? <MenuUnfold width={12} height={12} /> : <MenuFold width={12} height={12} />}
      </Button>
    </div>
  );
};

export default memo(NavigationSidebarHeader);
