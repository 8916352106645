import { createAction } from '@reduxjs/toolkit';
import { GetItemsAction, PutItemAction } from './types';

const ACTION_PREFIX = 'item';

export const getItemsAction: GetItemsAction = {
  success: createAction(`${ACTION_PREFIX}/getItems/success`),
};

export const putItemAction: PutItemAction = {
  start: createAction(`${ACTION_PREFIX}/putItem/start`),
  success: createAction(`${ACTION_PREFIX}/putItem/success`),
  failure: createAction(`${ACTION_PREFIX}/putItem/failure`),
};
