import { createAction } from '@reduxjs/toolkit';
import { GetTagsAction } from './types';

const ACTION_PREFIX = 'tag';

export const getTagsAction: GetTagsAction = {
  start: createAction(`${ACTION_PREFIX}/getTags/start`),
  success: createAction(`${ACTION_PREFIX}/getTags/success`),
  failure: createAction(`${ACTION_PREFIX}/getTags/failure`),
};
