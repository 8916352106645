import { getIsSwitchActive } from 'shared/lib';
import { Item } from 'entities/item/model';

export const getIsItemActive = (item: Item): boolean => {
  if (!item.isValueUpdateAllowed) {
    return true;
  }

  switch (item.type) {
    case 'switch':
      return getIsSwitchActive(item.value);
    case 'text':
      return true;
    case 'number':
      return item.value !== '0';
    default:
      return false;
  }
};
