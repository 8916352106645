import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Layout, Menu } from 'antd';

import { selectedBoxSelector } from 'entities/box/model';
import { getTagsAction } from 'entities/tag/model';
import { ROUTES } from 'router/routes';

import antMenuCustom from 'styles/ant-custom/menu.module.scss';

import { useSidebarItems } from '../lib/hooks/useSidebarItems';
import { useSelectedSidebarItems } from '../lib/hooks/useSelectedSidebarItems';
import NavigationSidebarHeader from './header/NavigationSidebarHeader';

const { Sider } = Layout;

function NavigationSidebar(): ReactElement {
  const [collapsed, setCollapsed] = useState(false);

  const { navItems } = useSidebarItems();
  const { selectedItemKeys } = useSelectedSidebarItems(navItems);

  const handleToggleBtnClick = useCallback(() => setCollapsed((v) => !v), []);

  const dispatch = useDispatch();
  const box = useSelector(selectedBoxSelector);

  useEffect(() => {
    if (box) {
      dispatch(getTagsAction.start({ boxId: box.id }));
    }
  }, [box, dispatch]);

  return (
    <Sider
      theme="light"
      collapsible
      collapsed={collapsed}
      trigger={null}
      breakpoint="sm"
      width={240}
      onCollapse={handleToggleBtnClick}
      data-testid="navigation-sidebar"
    >
      <NavigationSidebarHeader collapsed={collapsed} handleToggleBtnClick={handleToggleBtnClick} />
      <Menu
        mode="inline"
        items={navItems}
        defaultOpenKeys={[ROUTES.DEVICES.path]}
        selectedKeys={selectedItemKeys}
        className={antMenuCustom.itemSelectedPrimary}
      />
    </Sider>
  );
}

export default NavigationSidebar;
