import { createReducer, AnyAction } from '@reduxjs/toolkit';

import { DEFAULT_LANGUAGE } from 'constants/defaultAppConfig';
import { IUiState } from 'store/ui/types';

import * as actions from './actions';

export const uiInitialState: IUiState = {
  selectedLanguageCode: DEFAULT_LANGUAGE,
};

export const uiReducer = createReducer(uiInitialState, (builder) => {
  builder.addCase(actions.setLanguage, (state, action: AnyAction) => ({
    ...state,
    selectedLanguageCode: action.payload.languageCode,
  }));
});
