import {
  all,
  put,
  PutEffect,
  CallEffect,
  call,
  throttle,
  TakeEffect,
  RaceEffect,
  take,
  race,
  delay,
  fork,
} from 'redux-saga/effects';
import { POLL_INTERVAL } from 'shared/consts';

import { getRules, putRule } from '../api/requests';
import { RuleDTO } from './types';

import { getRulesAction, putRuleAction, pollRulesAction } from './actions';

export function* getRulesSaga(
  action: ReturnType<typeof getRulesAction.start>,
): Generator<PutEffect | CallEffect, void, RuleDTO[]> {
  const { boxId } = action.payload;
  let responseData: RuleDTO[];

  while (true) {
    try {
      responseData = yield call(getRules, boxId);

      yield put(getRulesAction.success(responseData));
      yield delay(POLL_INTERVAL);
    } catch {
      yield put(pollRulesAction.stop());
      yield put(getRulesAction.failure());
    }
  }
}

export function* startPollingRules(): Generator<
  TakeEffect | RaceEffect<TakeEffect | CallEffect>,
  void,
  ReturnType<typeof pollRulesAction.start>
> {
  while (true) {
    const action = yield take(pollRulesAction.start.type);
    yield race([call(getRulesSaga, action), take(pollRulesAction.stop.type)]);
  }
}

export function* putRuleSaga(
  action: ReturnType<typeof putRuleAction.start>,
): Generator<PutEffect | CallEffect, void, RuleDTO[]> {
  const { boxId, ruleName, enable } = action.payload;

  try {
    yield call(putRule, boxId, ruleName, enable);
    yield put(putRuleAction.success());
  } catch (e) {
    yield put(putRuleAction.failure());
  }
}

export function* ruleSagas(): Generator {
  yield all([yield fork(startPollingRules), yield throttle(500, putRuleAction.start, putRuleSaga)]);
}
