import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { checkEpochTimeStampOutdated } from 'shared/lib';
import { POLL_INTERVAL } from 'shared/consts';
import { selectedBoxSelector } from 'entities/box/model';

import { rulesLastPatchTimestampSelector, pollRulesAction } from 'entities/rule/model';

const rulePatchPollInterval = 300;

export const useSmartPolling = (): void => {
  const dispatch = useDispatch();
  const box = useSelector(selectedBoxSelector);

  const rulesLastPatchTimestamp = useSelector(rulesLastPatchTimestampSelector);

  const isBackendPollingActive = useRef(true);

  useEffect(() => {
    if (box?.id) {
      dispatch(pollRulesAction.start({ boxId: box.id }));
    }
  }, [dispatch, box]);

  useEffect(() => {
    if (!box) {
      return undefined;
    }

    const timer = setInterval(() => {
      if (!rulesLastPatchTimestamp) {
        return;
      }

      const rulePatchOutdated = checkEpochTimeStampOutdated(rulesLastPatchTimestamp, POLL_INTERVAL);

      if (rulePatchOutdated && !isBackendPollingActive.current) {
        dispatch(pollRulesAction.start({ boxId: box.id }));
        isBackendPollingActive.current = true;
        return;
      }

      if (!rulePatchOutdated && isBackendPollingActive.current) {
        dispatch(pollRulesAction.stop());
        isBackendPollingActive.current = false;
      }
    }, rulePatchPollInterval);

    return () => {
      clearInterval(timer);
      dispatch(pollRulesAction.stop());
      isBackendPollingActive.current = false;
    };
  }, [box, rulesLastPatchTimestamp, dispatch]);
};
