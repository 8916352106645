import React, { ReactElement } from 'react';
import { Image } from 'antd';

import whiteLogo from 'assets/logo_white.svg';
import blackLogo from 'assets/logo_black.svg';

const logoColorMap: { [key: string]: string } = {
  white: whiteLogo,
  black: blackLogo,
};

type LogoColor = 'white' | 'black';
type LogoProps = { width?: number; color?: LogoColor };

function Logo({ width = 100, color = 'black' }: LogoProps): ReactElement {
  const logo = logoColorMap[color];

  return <Image width={width} src={logo} preview={false} data-testid="logo" />;
}

export default Logo;
