import { createAction } from '@reduxjs/toolkit';
import * as userTypes from 'store/user/types';

const ACTION_PREFIX = 'user';

export const exampleAction: userTypes.ExampleAction = {
  start: createAction(`${ACTION_PREFIX}/example/start`),
  success: createAction(`${ACTION_PREFIX}/example/success`),
  failure: createAction(`${ACTION_PREFIX}/example/failure`),
};
