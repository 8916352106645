import { Fragment, ReactElement } from 'react';

import { filterIds } from 'shared/lib';
import Status from './itemStatus/ItemStatus';

type Props = {
  itemIds: UniqueId[];
  excludedItemIds: UniqueId[];
};
function ItemsStatus({ itemIds, excludedItemIds }: Props): ReactElement {
  const ids = filterIds(itemIds, excludedItemIds);

  return (
    <span>
      {ids.map((id, index) => (
        <Fragment key={id}>
          <Status itemId={id} /> {index === ids.length - 1 ? '' : ' | '}
        </Fragment>
      ))}
    </span>
  );
}

export default ItemsStatus;
