import { createReducer } from '@reduxjs/toolkit';

import { getEpochTimeStamp } from 'shared/lib';

import { IItemState } from './types';
import { getItemsAction, putItemAction } from './actions';
import { mapItemsDTO } from '../lib/mappers/mapItemsDTO';

export const itemInitialState: IItemState = {
  items: {
    isLoading: false,
    data: {},
  },
};

export const itemReducer = createReducer(itemInitialState, (builder) => {
  builder
    .addCase(getItemsAction.success, (state, action) => ({
      ...state,
      items: {
        ...state.items,
        data: mapItemsDTO(action.payload),
        isLoading: false,
      },
    }))
    // no need to add .start.
    // There is not even needed .failure handler here,
    // because polling and/or sockets will re-create the correct application state
    .addCase(putItemAction.success, (state, { payload: { itemId, value } }) => ({
      ...state,
      items: {
        ...state.items,
        lastPatchTimestamp: getEpochTimeStamp(),
        data: { ...state.items.data, [itemId]: { ...state.items.data[itemId], value } },
      },
    }));
});
