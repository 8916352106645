import React, { ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row, Typography } from 'antd';

import { getBoxesAction } from 'entities/box/model';
import { isAuthorizedSelector } from 'store/auth/selectors';
import NavigationService from 'services/navigation/NavigationService';
import { Logo } from 'shared/ui';
import { LoginForm } from 'features/loginForm';

import styles from './LoginPage.module.scss';

const { Title } = Typography;

function LoginPage(): ReactElement {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const isAuthorized = useSelector(isAuthorizedSelector);

  useEffect(() => {
    if (isAuthorized) {
      NavigationService.navigateToDefaultPath();
      dispatch(getBoxesAction.start());
    }
  }, [isAuthorized]);

  return (
    <Row className={styles.loginPage} data-testid="log-in-page">
      <Col xs={0} sm={0} md={12} className={styles.leftColumn}>
        <div className={styles.leftColumnContentWrapper}>
          <Logo width={50} color="white" />

          <span className={styles.slogan}>
            {t('Auth:LoginPage.welcomePart1')}
            <br />
            {t('Auth:LoginPage.welcomePart2')}
          </span>
        </div>
      </Col>
      <Col xs={24} sm={24} md={12} className={styles.rightColumn}>
        <div className={styles.formWrapper}>
          <Title level={4}>{t('Auth:LoginForm.title')}</Title>
          <LoginForm />
        </div>
      </Col>
    </Row>
  );
}

export default LoginPage;
