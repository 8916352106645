import { createAction } from '@reduxjs/toolkit';
import { GetRulesAction, PollRulesAction, PutRuleAction } from './types';

const ACTION_PREFIX = 'rule';

export const getRulesAction: GetRulesAction = {
  start: createAction(`${ACTION_PREFIX}/getRules/start`),
  success: createAction(`${ACTION_PREFIX}/getRules/success`),
  failure: createAction(`${ACTION_PREFIX}/getRules/failure`),
};

export const putRuleAction: PutRuleAction = {
  start: createAction(`${ACTION_PREFIX}/putRule/start`),
  success: createAction(`${ACTION_PREFIX}/putRule/success`),
  failure: createAction(`${ACTION_PREFIX}/putRule/failure`),
};

export const pollRulesAction: PollRulesAction = {
  start: createAction(`${ACTION_PREFIX}/pollRules/start`),
  stop: createAction(`${ACTION_PREFIX}/pollRules/stop`),
};
