import { ReactElement, ReactNode } from 'react';
import { Card, Typography } from 'antd';
import classNames from 'classnames';

import antCardCustom from 'styles/ant-custom/card.module.scss';
import { Device } from 'entities/device/model';
import { ItemsStatus } from 'entities/item/ui';

import { useDeviceTemplate } from './hooks';

import styles from './DeviceCardTemplate.module.scss';

type Props = {
  device: Device;
  isCardDisabled: boolean;
  switcher?: ReactElement;
  slider?: ReactElement;
  primaryItemId: UniqueId;
  primaryItemStatus?: ReactNode;
};

function DeviceCardTemplate({
  device,
  isCardDisabled,
  switcher,
  slider,
  primaryItemId,
  primaryItemStatus,
}: Props): ReactElement {
  const { icons } = useDeviceTemplate(device.type);

  return (
    <Card
      className={classNames(antCardCustom.zeroPadding, styles.deviceCard, {
        [styles.cardDisabled]: isCardDisabled,
      })}
    >
      <div className={styles.cardContent}>
        <div className={styles.top}>
          <div className={styles.iconsWrapper}>
            <div className={classNames(styles.icon, styles.iconActive)}>{icons.active}</div>
            <div className={classNames(styles.icon, styles.iconDisabled)}>{icons.disabled}</div>
          </div>
          <div className={styles.controlWrapper}>{switcher}</div>
        </div>
        {slider}
        <div className={styles.bottom}>
          <Typography.Text className={styles.name}>{device.name}</Typography.Text>
          {primaryItemStatus ? <Typography.Text className={styles.state}>{primaryItemStatus}</Typography.Text> : null}
          <Typography.Text className={styles.state}>
            <ItemsStatus itemIds={device.itemIds} excludedItemIds={[primaryItemId]} />
          </Typography.Text>
        </div>
      </div>
    </Card>
  );
}

// no need to use memo here, because this element is totally dependent on parent Component
export default DeviceCardTemplate;
