import React from 'react';
import { Navigate, useLocation, Outlet } from 'react-router-dom';

import { ROUTES } from 'router/routes';

import UserService from 'services/user/UserService';
import NavigationService from 'services/navigation/NavigationService';

import { UserRole } from 'constants/userRoles';

function ProtectedRoute({ requiredRole }: { requiredRole: UserRole }): React.ReactElement {
  const location = useLocation();

  const isUserAuthorized = UserService.isAuthorized();

  const redirectTo = isUserAuthorized ? NavigationService.getDefaultRedirectPath() : ROUTES.LOG_IN.path;

  const isRouteAllowedForCurrentRole = UserService.isHasAccess(requiredRole);
  const hasAccessToRoute = isUserAuthorized && isRouteAllowedForCurrentRole;

  const isLogInPage = location.pathname === `/${ROUTES.LOG_IN.path}`;

  const shouldBeRedirected = isLogInPage ? isUserAuthorized : !hasAccessToRoute;

  if (shouldBeRedirected) {
    return <Navigate to={`/${redirectTo}`} state={{ from: location }} replace />;
  }

  return <Outlet />;
}

export default ProtectedRoute;
