// eslint-disable-next-line import/no-extraneous-dependencies
import i18n from 'i18next';
import { store } from 'store/store';
import LocalStorageService from 'services/localStorage/LocalStorageService';
import { ISOLanguageCodes } from 'constants/language';
import * as uiActions from 'store/ui/actions';

const LanguageService = {
  setDocumentLangAttribute(langCode: ISOLanguageCodes) {
    document.documentElement.lang = langCode;
  },
  changeLanguage(locale: ISOLanguageCodes) {
    LocalStorageService.setItem('locale', locale);
    i18n.changeLanguage(locale);
    store.dispatch(uiActions.setLanguage({ languageCode: locale }));
    this.setDocumentLangAttribute(locale);
  },
  getCurrentLanguage() {
    return i18n.language;
  },
  isCurrentLanguageIsEnglish() {
    return this.getCurrentLanguage() === ISOLanguageCodes.ENGLISH;
  },
  isCurrentLanguageIsRussian() {
    return this.getCurrentLanguage() === ISOLanguageCodes.RUSSIAN;
  },
};

export default LanguageService;
