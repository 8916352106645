import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form, Input } from 'antd';

import { useLogin } from '../lib/hooks/useLogin';

import styles from './LoginForm.module.scss';

function LoginForm(): ReactElement {
  const { t } = useTranslation();
  const { isLoading, handleLogin } = useLogin();

  return (
    <Form layout="vertical" name="login" onFinish={handleLogin} data-testid="log-in-form">
      <Form.Item
        name="login"
        label={t('Auth:LoginForm.login.label')}
        rules={[
          {
            required: true,
            message: t('Auth:LoginForm.requiredLogin.message'),
          },
        ]}
      >
        <Input placeholder={t('Auth:LoginForm.login.placeholder')} />
      </Form.Item>
      <Form.Item
        name="password"
        label={t('Auth:LoginForm.password.label')}
        rules={[{ required: true, message: t('Auth:LoginForm.requiredPassword.message') }]}
      >
        <Input type="password" placeholder={t('Auth:LoginForm.password.placeholder')} />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" className={styles.loginFormSubmitButton} loading={isLoading}>
          {t('Auth:LoginForm.submitButton')}
        </Button>
      </Form.Item>
    </Form>
  );
}

export default LoginForm;
