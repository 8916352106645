import { API } from './apiTools';

const DOMAIN = process.env.REACT_APP_DOMAIN;

export const api = new API(DOMAIN);

export const apiRequest = api.req;

export const ping = (): Promise<void> => {
  return apiRequest.get('/ping');
};
