import { all, call, put, takeLatest, CallEffect, PutEffect } from 'redux-saga/effects';
import { getTags } from '../api/requests';
import { TagDTO } from './types';

import { getTagsAction } from './actions';

export function* getTagsSaga(
  action: ReturnType<typeof getTagsAction.start>,
): Generator<PutEffect | CallEffect, void, TagDTO[]> {
  const {
    payload: { boxId },
  } = action;
  let responseData: TagDTO[];

  try {
    responseData = yield call(getTags, boxId);
  } catch {
    yield put(getTagsAction.failure());
    return;
  }

  yield put(getTagsAction.success(responseData));
}

export function* tagSagas(): Generator {
  yield all([yield takeLatest(getTagsAction.start, getTagsSaga)]);
}
