import { createReducer } from '@reduxjs/toolkit';

import { ITagState } from './types';
import { getTagsAction } from './actions';

export const tagInitialState: ITagState = {
  tags: {
    isLoading: false,
    data: [],
  },
};

export const tagReducer = createReducer(tagInitialState, (builder) => {
  builder
    .addCase(getTagsAction.start, (state) => ({
      ...state,
      tags: {
        ...state.tags,
        isLoading: true,
      },
    }))
    .addCase(getTagsAction.success, (state, action) => ({
      ...state,
      tags: {
        ...state.tags,
        data: action.payload,
        isLoading: false,
      },
    }))
    .addCase(getTagsAction.failure, (state) => ({
      ...state,
      devices: {
        ...state.tags,
        isLoading: false,
      },
    }));
});
