import { createReducer } from '@reduxjs/toolkit';

import { IUserState } from 'store/user/types';

import * as actions from './actions';

export const userInitialState: IUserState = {
  example: {},
};

export const userReducer = createReducer(userInitialState, (builder) => {
  builder
    .addCase(actions.exampleAction.start, (state) => ({
      ...state,
    }))
    .addCase(actions.exampleAction.success, (state, action) => ({
      ...state,
      example: action.payload.example,
    }))
    .addCase(actions.exampleAction.failure, (state) => ({
      ...state,
    }));
});
