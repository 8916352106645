import React, { ReactElement } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { ROUTES } from 'router/routes';
import NavigationService from 'services/navigation/NavigationService';

import CleanPageLayout from 'layouts/topLevelLayouts/CleanPageLayout/CleanPageLayout';
import PageWithHeaderLayout from 'layouts/topLevelLayouts/PageWithHeaderLayout/PageWithHeaderLayout';

import { IS_DEVELOPMENT } from 'shared/consts';

import LoginPage from 'pages/LoginPage/LoginPage';
import LogOutPage from 'pages/LogOutPage/LogOutPage';
import NotFoundPage from 'pages/NotFoundPage/NotFoundPage';
import DashboardPage from 'pages/DashboardPage/DashboardPage';
import DevicePage from 'pages/DevicePage/DevicePage';
import DevModePage from 'pages/DevModePage/DevModePage';
import RulesPage from 'pages/RulesPage/RulesPage';
import ProtectedRoute from './ProtectedRoute';

function ProtectedRouter(): ReactElement {
  return (
    <Routes>
      {/* Clean Page Layout */}
      <Route element={<CleanPageLayout />}>
        <Route element={<ProtectedRoute requiredRole={ROUTES.LOG_IN.requiredRole} />}>
          <Route path={ROUTES.LOG_IN.path} element={<LoginPage />} />
        </Route>
        <Route path={ROUTES.NOT_FOUND.path} element={<NotFoundPage />} />
      </Route>
      {/* Logout Page */}
      <Route element={<ProtectedRoute requiredRole={ROUTES.LOG_OUT.requiredRole} />}>
        <Route path={ROUTES.LOG_OUT.path} element={<LogOutPage />} />
      </Route>
      {/* A Group of Page With Header Layout */}
      <Route element={<ProtectedRoute requiredRole={null} />}>
        <Route element={<PageWithHeaderLayout />}>
          <Route element={<ProtectedRoute requiredRole={ROUTES.DASHBOARD.requiredRole} />}>
            <Route path={ROUTES.DASHBOARD.path} element={<DashboardPage />} />
          </Route>
          <Route element={<ProtectedRoute requiredRole={ROUTES.DEVICES.requiredRole} />}>
            <Route path={`${ROUTES.DEVICES.path}`} element={<DevicePage />} />
            <Route path={`${ROUTES.DEVICES.path}/:tag`} element={<DevicePage />} />
          </Route>
          <Route element={<ProtectedRoute requiredRole={ROUTES.AUTOMATION.requiredRole} />}>
            <Route path={ROUTES.AUTOMATION.path} element={<RulesPage />} />
          </Route>
        </Route>
      </Route>
      {/* For Development Only Routes */}
      {IS_DEVELOPMENT ? (
        <Route element={<ProtectedRoute requiredRole={null} />}>
          <Route path={ROUTES.DEV_MODE_PAGE.path} element={<DevModePage />} />
        </Route>
      ) : null}
      {/* Other Routes */}
      <Route path="/" element={<Navigate to={NavigationService.getDefaultRedirectPath()} replace />} />{' '}
      <Route path="*" element={<Navigate to={ROUTES.NOT_FOUND.path} replace />} />
    </Routes>
  );
}

export default ProtectedRouter;
