import { AuthorizedUserRoles, UserRole } from 'constants/userRoles';

export interface IRoute {
  path: string;
  requiredRole: UserRole;
}

type AllowedRoutes = 'LOG_IN' | 'LOG_OUT' | 'NOT_FOUND' | 'DASHBOARD' | 'DEV_MODE_PAGE' | 'DEVICES' | 'AUTOMATION';

export const ROUTES: Record<AllowedRoutes, IRoute> = {
  LOG_IN: {
    path: 'log-in',
    requiredRole: null,
  },
  LOG_OUT: {
    path: 'log-out',
    requiredRole: AuthorizedUserRoles.CLIENT,
  },

  DASHBOARD: {
    path: 'dashboard',
    requiredRole: AuthorizedUserRoles.CLIENT,
  },
  DEVICES: {
    path: 'devices',
    requiredRole: AuthorizedUserRoles.CLIENT,
  },
  AUTOMATION: {
    path: 'automation',
    requiredRole: AuthorizedUserRoles.CLIENT,
  },

  NOT_FOUND: {
    path: 'not-found',
    requiredRole: null,
  },

  DEV_MODE_PAGE: {
    path: 'dev-page',
    requiredRole: null,
  },
};
