import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { createMigrate, persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { rootReducer } from './rootReducer';
import rootSaga from './rootSaga';
import { migrations } from './migrations';

const CURRENT_STATE_VERSION = 0;

const rootPersistConfig = {
  key: 'root',
  storage,
  whitelist: ['ui', 'auth', 'box'],
  version: CURRENT_STATE_VERSION,
  migrate: createMigrate(migrations, { debug: false }),
};

export const persistedReducer = persistReducer(rootPersistConfig, rootReducer);

const sagaMiddleware = createSagaMiddleware({
  onError: () => {
    // TODO: Throw notification here
  },
});

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [sagaMiddleware],
  devTools: process.env.NODE_ENV !== 'production',
});

export const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);
