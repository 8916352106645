import { AuthorizedUserRoles, UserRole } from 'constants/userRoles';
import { isAuthorizedSelector } from 'store/auth/selectors';
import { store } from 'store/store';

const UserService = {
  isAuthorized() {
    return isAuthorizedSelector(store.getState());
  },

  getCurrentUserRole(): UserRole {
    // TODO: implement
    return AuthorizedUserRoles.CLIENT;
  },

  isHasAccess(requiredRole: UserRole): boolean {
    if (!requiredRole) {
      return true;
    }

    const currentRole = this.getCurrentUserRole();

    return requiredRole === currentRole;
  },
};

export default UserService;
