import React, { ReactElement } from 'react';

import { DeviceList } from 'widgets/deviceList';

import styles from './DevicePage.module.scss';

function DevicePage(): ReactElement {
  return (
    <div className={styles.devicePage} data-testid="device-page">
      <DeviceList />
    </div>
  );
}

export default DevicePage;
