export function sortByName<T extends { name: string }>(array: T[], asc = true): T[] {
  // asc: A->Z
  return array.sort((a, b) => {
    if (a.name < b.name) {
      return asc ? -1 : 1;
    }
    if (a.name > b.name) {
      return asc ? 1 : -1;
    }
    return 0;
  });
}
