import { all, takeLatest, put, PutEffect, CallEffect, call } from 'redux-saga/effects';
import { getBoxes } from '../api/requests';
import { BoxDTO } from './types';

import { getBoxesAction } from './actions';

export function* getBoxesSaga(): Generator<PutEffect | CallEffect, void, BoxDTO[]> {
  let responseData: BoxDTO[];

  try {
    responseData = yield call(getBoxes);
  } catch (error: unknown) {
    yield put(getBoxesAction.failure());
    return;
  }

  yield put(getBoxesAction.success(responseData));
}

export function* boxSagas(): Generator {
  yield all([yield takeLatest(getBoxesAction.start, getBoxesSaga)]);
}
