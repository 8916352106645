import LocalStorageService from 'services/localStorage/LocalStorageService';
import LanguageService from 'services/language/LanguageService';
import { ISOLanguageCodes } from 'constants/language';
import { DEFAULT_LANGUAGE } from 'constants/defaultAppConfig';

const AppService = {
  initApp() {
    const storedLocale = (LocalStorageService.getItem('locale') ?? DEFAULT_LANGUAGE) as ISOLanguageCodes;
    LanguageService.setDocumentLangAttribute(storedLocale);
  },
};

export default AppService;
