/* eslint-disable no-param-reassign */
import { RefObject, useEffect } from 'react';

const load = (src: string): Promise<Event> =>
  new Promise((resolve, reject) => {
    const image = new Image();

    image.src = src;
    image.addEventListener('load', resolve);
    image.addEventListener('error', reject);
  });

export const useBackdrop = (ref: RefObject<HTMLElement | null>, bgColor: string, img: string): void => {
  useEffect(() => {
    if (ref.current) {
      ref.current.style.backgroundColor = bgColor;
    }

    load(img)
      .then(() => {
        if (ref.current) {
          ref.current.style.backgroundImage = `url(${img})`;
        }
      })
      // todo: error-handling
      // eslint-disable-next-line no-console
      .catch((err) => console.log(err));
  }, [ref, bgColor, img]);
};
