import { createReducer } from '@reduxjs/toolkit';

import { IAuthState } from 'store/auth/types';

import * as actions from './actions';

export const authInitialState: IAuthState = {
  isAuthorized: false,
  logIn: {
    isLoading: false,
  },
  authenticatedUser: {
    isLoading: false,
    info: {},
  },
  logOut: {
    isLoading: false,
  },
};

export const authReducer = createReducer(authInitialState, (builder) => {
  builder
    // logIn
    .addCase(actions.logIn.start, (state) => ({
      ...state,
      logIn: {
        isLoading: true,
      },
    }))
    .addCase(actions.logIn.success, (state, action) => ({
      ...state,
      isAuthorized: action.payload.isAuthorized,
      logIn: {
        isLoading: false,
      },
    }))
    .addCase(actions.logIn.failure, (state) => ({
      ...state,
      isAuthorized: false,
      logIn: {
        isLoading: false,
      },
    }))
    // getAuthenticatedUser
    .addCase(actions.getAuthenticatedUser.start, (state) => ({
      ...state,
      authenticatedUser: {
        ...state.authenticatedUser,
        isLoading: true,
      },
    }))
    .addCase(actions.getAuthenticatedUser.success, (state, action) => ({
      ...state,
      authenticatedUser: {
        isLoading: false,
        info: action.payload,
      },
    }))
    .addCase(actions.getAuthenticatedUser.failure, (state) => ({
      ...state,
      authenticatedUser: {
        isLoading: false,
        info: {},
      },
    }))
    // logOut
    .addCase(actions.logOut.start, (state) => ({
      ...state,
      logOut: {
        isLoading: true,
      },
    }))
    .addCase(actions.logOut.success, (state) => ({
      ...state,
      logOut: {
        isLoading: false,
      },
    }))
    .addCase(actions.logOut.failure, (state) => ({
      ...state,
      logOut: {
        isLoading: false,
      },
    }));
});
