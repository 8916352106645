import React, { ReactElement, useRef } from 'react';
import { Outlet } from 'react-router-dom';
import { Layout } from 'antd';

import bg from 'assets/app_background.png';
import bgCompressed from 'assets/app_background_compressed.png';

import { useBackdrop } from 'hooks/useBackdrop';
import { Header } from 'widgets/header';
import { NavigationSidebar } from 'features/navigationSidebar';

import styles from './PageWithHeaderLayout.module.scss';

const { Content } = Layout;

function PageWithHeaderLayout(): ReactElement {
  const layoutRef = useRef<HTMLElement | null>(null);

  useBackdrop(layoutRef, '#BBAD9C', bgCompressed);
  useBackdrop(layoutRef, '#BBAD9C', bg);

  return (
    <Layout className={styles.pageWithHeaderLayout} data-testid="page-with-header-layout">
      <NavigationSidebar />

      <Layout className={styles.contentWrapper}>
        <Header />

        <Content className={styles.mainContent} ref={layoutRef}>
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
}

export default PageWithHeaderLayout;
