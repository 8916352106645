import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import LocalStorageService from 'services/localStorage/LocalStorageService';
import { DEFAULT_LANGUAGE } from 'constants/defaultAppConfig';
import { ISOLanguageCodes } from 'constants/language';

import AuthEN from 'i18nLocale/AuthEN.json';
import AuthRU from 'i18nLocale/AuthRU.json';
import NotFoundEN from 'i18nLocale/NotFoundEN.json';
import NotFoundRU from 'i18nLocale/NotFoundRU.json';
import UIEN from 'i18nLocale/UIEN.json';
import UIRU from 'i18nLocale/UIRU.json';

i18n.use(initReactI18next).init({
  resources: {},
  lng: LocalStorageService.getItem('locale') ?? DEFAULT_LANGUAGE,
  keySeparator: false,
  debug: false,
  interpolation: {
    escapeValue: false,
  },
});

const TranslatedISOLanguagesCodes = [ISOLanguageCodes.ENGLISH, ISOLanguageCodes.RUSSIAN] as const;

type LanguageItem = Record<string, string | Record<string, string>>;

type LanguageSet = Record<(typeof TranslatedISOLanguagesCodes)[number], LanguageItem>;

interface II18ConfigMap {
  Auth: LanguageSet;
  UI: LanguageSet;
  NotFound: LanguageSet;
}

const i18ConfigMap: II18ConfigMap = {
  Auth: { en: AuthEN, ru: AuthRU },
  NotFound: { en: NotFoundEN, ru: NotFoundRU },
  UI: { en: UIEN, ru: UIRU },
};

Object.keys(i18ConfigMap).forEach((page) => {
  const list = i18ConfigMap[page as keyof II18ConfigMap];

  Object.keys(list).forEach((lang: string) => {
    i18n.addResourceBundle(lang, page, list[lang as keyof LanguageSet], true, true);
  });
});

export default i18n;
