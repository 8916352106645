import React, { ReactElement, useEffect } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import Router from 'router/Router';
import { ErrorBoundary } from 'shared/ui';
import { UIConfigProvider } from 'app/providers';
import AppService from 'services/app/AppService';

import { store, persistor } from 'store/store';

import 'services/language/i18n';
import 'styles/styles.scss';

export const router = createBrowserRouter([
  // match everything with "*"
  {
    path: '*',
    element: (
      <ErrorBoundary>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <UIConfigProvider>
              <Router />
            </UIConfigProvider>
          </PersistGate>
        </Provider>
      </ErrorBoundary>
    ),
  },
]);

function App(): ReactElement {
  useEffect(() => {
    AppService.initApp();
  }, []);

  return <RouterProvider router={router} />;
}

export default App;
