import { createAction } from '@reduxjs/toolkit';
import * as authTypes from 'store/auth/types';

const ACTION_PREFIX = 'auth';

export const logIn: authTypes.LogInAction = {
  start: createAction(`${ACTION_PREFIX}/logIn/start`),
  success: createAction(`${ACTION_PREFIX}/logIn/success`),
  failure: createAction(`${ACTION_PREFIX}/logIn/failure`),
};

export const getAuthenticatedUser: authTypes.GetAuthenticatedUserAction = {
  start: createAction(`${ACTION_PREFIX}/getAuthenticatedUser/start`),
  success: createAction(`${ACTION_PREFIX}/getAuthenticatedUser/success`),
  failure: createAction(`${ACTION_PREFIX}/getAuthenticatedUser/failure`),
};

export const logOut: authTypes.LogOutAction = {
  start: createAction(`${ACTION_PREFIX}/logOut/start`),
  success: createAction(`${ACTION_PREFIX}/logOut/success`),
  failure: createAction(`${ACTION_PREFIX}/logOut/failure`),
};
