import { ReactElement } from 'react';

import { useItem } from 'entities/item/hooks';

import DeviceCardTemplate from '../deviceCardTemplate/DeviceCardTemplate';
import { DeviceCardTProps } from '../types';

function DeviceCardDefault({ device }: DeviceCardTProps): ReactElement {
  const { isItemActive, itemStatus } = useItem(device.primaryItemId);

  return (
    <DeviceCardTemplate
      device={device}
      isCardDisabled={!isItemActive}
      primaryItemId={device.primaryItemId}
      primaryItemStatus={itemStatus}
    />
  );
}

export default DeviceCardDefault;
