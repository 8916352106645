import axios, { AxiosInstance, AxiosRequestConfig, CreateAxiosDefaults } from 'axios';
import { useCallback, useEffect, useRef } from 'react';

import { ROUTES } from 'router/routes';

import LocalStorageService from 'services/localStorage/LocalStorageService';
import NavigationService from 'services/navigation/NavigationService';

// Type for all methods except Get
type RequestMethod = <ResponseType, BodyType>(
  url: string,
  body?: BodyType,
  config?: AxiosRequestConfig,
) => Promise<ResponseType>;

// Type for Get method
type RequestMethodGet = <ResponseType>(url: string, config?: AxiosRequestConfig) => Promise<ResponseType>;

type Methods = 'post' | 'patch' | 'put' | 'delete';

type MethodsObject = Record<Methods, RequestMethod> & { get: RequestMethodGet };

export const apiAccessTokenName = 'access';
export const apiRefreshTokenName = 'refresh';

export const getQueryString = (data: { [key: string]: Maybe<string> } = {}, withEmptyParams = false): string => {
  const paramsKeys = Object.keys(data);
  let res = '';
  if (paramsKeys.length === 0) {
    return res;
  }

  paramsKeys.forEach((key, index: number) => {
    if (!withEmptyParams && (data[key] === '' || data[key] === null)) {
      return;
    }
    res += `${index === 0 ? '?' : '&'}${key}=${data[key]}`;
  });

  return res;
};

export class API {
  axios: AxiosInstance;

  methods: Methods[] = ['post', 'patch', 'put', 'delete'];

  req: MethodsObject;

  refreshRequest: Promise<void> | null = null;

  constructor(domain: string | undefined, options: Omit<CreateAxiosDefaults, 'baseUrl'> = {}) {
    this.axios = axios.create({
      baseURL: domain,
      ...options,
    });
    // function to create initial request methods object to not give them optional types
    this.req = this.methods.reduce((acc, item) => ({ ...acc, [item]: null }), {} as MethodsObject);

    const { errorResponseInterceptor } = this;

    this.axios.interceptors.response.use((data) => data, errorResponseInterceptor.bind(this));

    const createMethod = (method: Methods): void => {
      this.req[method] = this._createRequest(method);
    };
    // create Get method separately to have appropriate typing for it
    this.req.get = async (url, config) => {
      const params = [url, ...(config ? [config] : [])] as const;
      const response = await this.axios.get(...params);
      return response.data;
    };
    this.methods.forEach(createMethod);
  }

  private _createRequest(method: Methods): RequestMethod {
    return async (url, body, config) => {
      const params = [url, ...(body ? [body] : []), ...(config ? [config] : [])] as const;
      const response = await this.axios[method](...params);
      return response.data;
    };
  }

  async errorResponseInterceptor(error: { [key: string]: any }): Promise<any> {
    const { response = {} } = error;
    const { status } = response;
    const originalConfig = error.config;

    if (status === 401) {
      this.deleteTokens();

      if (!NavigationService.isLogInPage()) {
        NavigationService.navigateToPath(ROUTES.LOG_OUT.path);
      }
    }

    if (status === 403) {
      NavigationService.navigateToDefaultPath();
    }

    if (status === 404 && originalConfig.url !== '/api/user/this') {
      NavigationService.navigateToPath(ROUTES.NOT_FOUND.path);
    }

    return Promise.reject(error);
  }

  updateAuthorizationHeader(accessToken: string): void {
    this.axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  }

  saveTokens(accessToken: string, refreshToken: string): void {
    this.updateAuthorizationHeader(accessToken);
    LocalStorageService.setItem(apiAccessTokenName, accessToken);
    LocalStorageService.setItem(apiRefreshTokenName, refreshToken);
  }

  deleteTokens(): void {
    this.axios.defaults.headers.common.Authorization = '';
    LocalStorageService.clear(apiAccessTokenName);
    LocalStorageService.clear(apiRefreshTokenName);
  }
}

export function useAbortRequest(): [() => AbortSignal, (reason?: string) => void] {
  const axiosSource = useRef<AbortController | null>(null);

  const generateNewSignal = useCallback(() => {
    axiosSource.current = new AbortController();
    return axiosSource.current.signal;
  }, []);

  const abortRequest = (reason?: string): void => {
    if (axiosSource.current) axiosSource.current.abort(reason);
  };

  useEffect(() => {
    return () => axiosSource.current?.abort();
  }, []);

  return [generateNewSignal, (reason?: string) => abortRequest(reason)];
}
