import React, { ReactElement } from 'react';

import { RulesList } from 'widgets/rulesList';

import styles from './RulesPage.module.scss';

export default function RulesPage(): ReactElement {
  return (
    <div className={styles.pageWrapper}>
      <RulesList />
    </div>
  );
}
