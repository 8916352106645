import {
  CallEffect,
  PutEffect,
  RaceEffect,
  TakeEffect,
  all,
  call,
  delay,
  fork,
  put,
  race,
  take,
} from 'redux-saga/effects';

import { pullItemsDTOFromDevicesDTO } from 'shared/lib';
import { POLL_INTERVAL } from 'shared/consts';
import { getItemsAction } from 'entities/item/model';

import { getDevicesAction, pollDevicesAction } from './actions';
import { DeviceDTO, GetDevicesPayload } from './types';
import { getDevices } from '../api/requests';

export function* getDevicesSaga({ boxId }: GetDevicesPayload): Generator<PutEffect | CallEffect, void, DeviceDTO[]> {
  while (true) {
    try {
      const responseData: DeviceDTO[] = yield call(getDevices, boxId);
      yield put(getDevicesAction.success(responseData));
      yield put(getItemsAction.success(pullItemsDTOFromDevicesDTO(responseData)));
      yield delay(POLL_INTERVAL);
    } catch (err) {
      yield put(getDevicesAction.failure());
      // Once the polling has encountered an error, it should be stopped immediately
      yield put(pollDevicesAction.stop());
    }
  }
}

export function* startPollingDevices(): Generator<
  TakeEffect | RaceEffect<TakeEffect | CallEffect>,
  void,
  ReturnType<typeof pollDevicesAction.start>
> {
  while (true) {
    const action = yield take(pollDevicesAction.start.type);
    yield race([call(getDevicesSaga, action.payload), take(pollDevicesAction.stop.type)]);
  }
}

export function* deviceSagas(): Generator {
  yield all([yield fork(startPollingDevices)]);
}
