import { MigrationManifest, PersistState } from 'redux-persist';
import { IState } from 'store/types';

type PersistAppStateV0 = Pick<IState, 'ui' | 'auth'> & { _persist: PersistState };

export const migrations: MigrationManifest = {
  0: (state): PersistAppStateV0 | undefined => {
    if (!state) {
      return state;
    }

    const newState = state as PersistAppStateV0;

    return {
      ...newState,
    };
  },
};
