import React, { memo, ReactElement, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { List, Skeleton } from 'antd';
import { useParams } from 'react-router-dom';

import { IState } from 'store/types';
import { Device, devicesLoadingSelector, makeDevicesByTagSelector } from 'entities/device/model';
import { DeviceCard } from 'entities/device/ui';

import { useSmartPolling } from '../lib/hooks/useSmartPolling';

const renderDeviceCardByType = (device: Device): ReactElement => (
  <List.Item>
    <DeviceCard device={device} />
  </List.Item>
);

function DeviceList(): ReactElement {
  const { tag } = useParams();

  const devicesByTagSelector = useMemo(makeDevicesByTagSelector, []);
  const devices = useSelector((state: IState) => devicesByTagSelector(state, tag));
  const devicesLoading = useSelector(devicesLoadingSelector);

  useSmartPolling();

  return (
    <Skeleton loading={!devices.length && devicesLoading} active>
      <List grid={{ gutter: 16 }} dataSource={devices} renderItem={renderDeviceCardByType} rowKey="id" />
    </Skeleton>
  );
}

// Memo is needed here, because this is a self-independent component which should not be
// re-rendered because of parent component re-render
export default memo(DeviceList);
