import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';

import { ROUTES } from 'router/routes';
import { Logo } from 'shared/ui';

import styles from './NotFoundPage.module.scss';

function NotFoundPage(): ReactElement {
  const { t } = useTranslation();

  return (
    <div className={styles.notFoundPage} data-testid="not-found-page">
      <Logo width={200} />
      <div className={styles.errorCode}>404</div>
      <p className={styles.errorText}>{t('NotFound:NotFoundPage.heading')}</p>
      <Button type="primary" htmlType="button" className={styles.goHomeButton} href={`/${ROUTES.DASHBOARD.path}`}>
        {t('NotFound:NotFoundPage.goHomeButton')}
      </Button>
    </div>
  );
}

export default NotFoundPage;
