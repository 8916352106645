import { ReactElement, useEffect, useRef, useState } from 'react';
import { SwitchChangeEventHandler } from 'antd/lib/switch';
import { Slider, Switch as AntSwitch } from 'antd';

import { useItem } from 'entities/item/hooks';

import DeviceCardTemplate from '../deviceCardTemplate/DeviceCardTemplate';
import { DeviceCardTProps } from '../types';

function DeviceCardNumber({ device }: DeviceCardTProps): ReactElement {
  const { item: primaryItem, isItemActive, itemStatus, onValueChange } = useItem(device.primaryItemId);
  const valueInt = parseInt(primaryItem.value, 10);
  // to store last ">0 value"
  const memoValue = useRef('100');

  // we have to have "local state" with value to handle Slider's state correctly
  const [value, setValue] = useState(valueInt);

  // watch "primaryItem value", and set it to slider once changed
  useEffect(() => setValue(valueInt), [valueInt]);

  const handleSliderChange = (val: number): void => {
    setValue(val);
  };

  const handleSliderAfterChange = (val: number): void => {
    // update the Store
    onValueChange(`${val}`);
  };

  const handleSwitch: SwitchChangeEventHandler = (checked, event) => {
    event.stopPropagation();

    // if it has been toggled off, memo it:
    if (!checked) {
      memoValue.current = `${value}`;
    }

    // grab memo value in case if it toggled On
    const newValue = checked ? memoValue.current : '0';

    // and update the Store
    onValueChange(newValue);
  };

  return (
    <DeviceCardTemplate
      device={device}
      isCardDisabled={!isItemActive}
      switcher={
        primaryItem.isValueUpdateAllowed ? <AntSwitch onChange={handleSwitch} checked={isItemActive} /> : undefined
      }
      slider={
        primaryItem.isValueUpdateAllowed ? (
          <Slider step={10} onChange={handleSliderChange} onAfterChange={handleSliderAfterChange} value={value} />
        ) : undefined
      }
      primaryItemId={device.primaryItemId}
      primaryItemStatus={itemStatus}
    />
  );
}

export default DeviceCardNumber;
