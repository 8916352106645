const { localStorage }: { localStorage: Storage } = window;

class LocalStorageService {
  static setItem<T>(key: string, value: T): void {
    localStorage.setItem(key, JSON.stringify(value));
  }

  static getItem(key: string): Maybe<string> {
    if (!localStorage.getItem(key)) {
      return null;
    }
    return JSON.parse(localStorage.getItem(key) || '{}');
  }

  static clear(key: string): void {
    if (key) {
      return localStorage.removeItem(key);
    }
    return localStorage.clear();
  }
}

export default LocalStorageService;
