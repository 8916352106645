import React, { ReactElement } from 'react';
import { Outlet } from 'react-router-dom';

import styles from './CleanPageLayout.module.scss';

function CleanPageLayout(): ReactElement {
  return (
    <div className={styles.cleanPageLayout} data-testid="clean-page-layout">
      <Outlet />
    </div>
  );
}

export default CleanPageLayout;
