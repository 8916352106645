import { createAction } from '@reduxjs/toolkit';
import { GetBoxesAction } from './types';

const ACTION_PREFIX = 'box';

export const getBoxesAction: GetBoxesAction = {
  start: createAction(`${ACTION_PREFIX}/getBoxes/start`),
  success: createAction(`${ACTION_PREFIX}/getBoxes/success`),
  failure: createAction(`${ACTION_PREFIX}/getBoxes/failure`),
};

export const changeSelectedBox = createAction<string>(`${ACTION_PREFIX}/changeSelectedBox`);
