import { memo, ReactElement } from 'react';

import { Device } from 'entities/device/model';

import DeviceCardSwitch from './deviceCardT/DeviceCardSwitch';
import DeviceCardNumber from './deviceCardT/DeviceCardNumber';
import DeviceCardDefault from './deviceCardT/DeviceCardDefault';

type Props = {
  device: Device;
};

function DeviceCard({ device }: Props): ReactElement {
  switch (device.primaryItemType) {
    case 'switch':
      return <DeviceCardSwitch device={device} />;
    case 'number':
      return <DeviceCardNumber device={device} />;
    default:
      return <DeviceCardDefault device={device} />;
  }
}

// Memo is needed here. The single parameter - device should trigger the component's render
export default memo(DeviceCard);
