import { createAction } from '@reduxjs/toolkit';
import { GetDevicesAction, PollDevicesAction } from './types';

const ACTION_PREFIX = 'device';

export const getDevicesAction: GetDevicesAction = {
  start: createAction(`${ACTION_PREFIX}/getDevices/start`),
  success: createAction(`${ACTION_PREFIX}/getDevices/success`),
  failure: createAction(`${ACTION_PREFIX}/getDevices/failure`),
};

export const pollDevicesAction: PollDevicesAction = {
  start: createAction(`${ACTION_PREFIX}/pollDevices/start`),
  stop: createAction(`${ACTION_PREFIX}/pollDevices/stop`),
};
