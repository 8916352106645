import { ReactElement } from 'react';
import { Avatar, Dropdown } from 'antd';

import { useMenuItems } from '../../lib/hooks/useMenuItems';

import styles from './UserMenu.module.scss';

function UserMenu(): ReactElement {
  const items = useMenuItems();

  return (
    <Dropdown menu={{ items }} trigger={['click']}>
      <Avatar className={styles.userMenu}>AH</Avatar>
    </Dropdown>
  );
}

export default UserMenu;
