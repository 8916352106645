import { createSelector } from '@reduxjs/toolkit';

import { IState } from 'store/types';
import { Item } from './types';

const itemsSelector = (state: IState): Record<UniqueId, Item> => state.item.items.data;
const selectItemId = (state: IState, id: UniqueId): UniqueId => id;

type ItemByIdSelector = (state: IState, id: UniqueId) => Item;
export const makeItemByIdSelector = (): ItemByIdSelector =>
  createSelector([itemsSelector, selectItemId], (items: Record<UniqueId, Item>, itemId: UniqueId) => {
    return items[itemId];
  });

export const itemsLoadingSelector = (state: IState): boolean => state.item.items.isLoading;

export const itemsLastPatchTimestampSelector = (state: IState): EpochTimeStamp | undefined =>
  state.item.items.lastPatchTimestamp;
