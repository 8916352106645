import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';
import { Link } from 'react-router-dom';

import styles from './NavigationSidebarItem.module.scss';

type Props = {
  label: string;
  path: string;
};

const NavigationSidebarItem = ({ label, path }: Props): ReactElement => {
  const { t } = useTranslation();

  return (
    <Link className={styles.link} to={path}>
      <Typography.Text>{t(label)}</Typography.Text>
    </Link>
  );
};

export default NavigationSidebarItem;
