import { apiRequest } from '../ApiService';
import * as userTypes from './types';

export const logIn = (credentials: userTypes.LogInPayload): Promise<userTypes.LogInResponse> => {
  return apiRequest.post('/auth', credentials);
};

export const register = (payload: userTypes.RegisterPayload): Promise<userTypes.RegisterResponse> => {
  return apiRequest.post('/register', payload);
};

export const getAuthenticatedUserInfo = (): Promise<userTypes.GetAuthenticatedUserInfoResponse> => {
  return apiRequest.get(`/me`);
};
