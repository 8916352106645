import { ReactComponent as PlayCircleIcon } from 'assets/play_circle.svg';
import { ReactComponent as SettingOutlinedIcon } from 'assets/setting_outlined.svg';
import { ReactComponent as DevicesOutlinedIcon } from 'assets/devices_outlined.svg';

import { ROUTES } from 'router/routes';

import { RawNavItem } from './types';

export const rawNavItems: RawNavItem[] = [
  {
    key: 'home',
    icon: <PlayCircleIcon width={18} height={18} />,
    label: 'UI:NavigationSidebar.items.home',
    path: `/${ROUTES.DASHBOARD.path}`,
    requiredRole: ROUTES.DASHBOARD.requiredRole,
  },
  {
    key: 'devices',
    icon: <DevicesOutlinedIcon width={18} height={18} />,
    label: 'UI:NavigationSidebar.items.devices',
    path: `/${ROUTES.DEVICES.path}`,
    requiredRole: ROUTES.DEVICES.requiredRole,
  },
  {
    key: 'automation',
    icon: <SettingOutlinedIcon width={18} height={18} />,
    label: 'UI:NavigationSidebar.items.automation',
    path: `/${ROUTES.AUTOMATION.path}`,
    requiredRole: ROUTES.AUTOMATION.requiredRole,
  },
];
