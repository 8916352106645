import React, { ReactElement } from 'react';

import { DeviceType } from 'entities/device/model';

import { ReactComponent as SwitchIcon } from 'assets/deviceIcons/switch/switch.svg';
import { ReactComponent as SwitchDisabledIcon } from 'assets/deviceIcons/switch/switch_disabled.svg';

import { ReactComponent as ConditionerIcon } from 'assets/deviceIcons/conditioner/conditioner.svg';
import { ReactComponent as ConditionerDisabledIcon } from 'assets/deviceIcons/conditioner/conditioner_disabled.svg';

import { ReactComponent as SocketIcon } from 'assets/deviceIcons/socket/socket.svg';
import { ReactComponent as SocketDisabledIcon } from 'assets/deviceIcons/socket/socket_disabled.svg';

import { ReactComponent as LockIcon } from 'assets/deviceIcons/lock/lock.svg';
import { ReactComponent as LockDisabled } from 'assets/deviceIcons/lock/lock_disabled.svg';

import { ReactComponent as CurtainIcon } from 'assets/deviceIcons/curtain/curtain.svg';
import { ReactComponent as CurtainDisabledIcon } from 'assets/deviceIcons/curtain/curtain_disabled.svg';

import { ReactComponent as LightIcon } from 'assets/deviceIcons/light/light.svg';
import { ReactComponent as LightDisabledIcon } from 'assets/deviceIcons/light/light_disabled.svg';

import { ReactComponent as ChandelierIcon } from 'assets/deviceIcons/chandelier/chandelier.svg';
import { ReactComponent as ChandelierDisabledIcon } from 'assets/deviceIcons/chandelier/chandelier_disabled.svg';

import { ReactComponent as FloorIcon } from 'assets/deviceIcons/floor/floor.svg';
import { ReactComponent as FloorDisabledIcon } from 'assets/deviceIcons/floor/floor_disabled.svg';

export const getDeviceIcons = (deviceType: DeviceType): { active: ReactElement; disabled: ReactElement } => {
  switch (deviceType) {
    case 'switch':
      return {
        active: <SwitchIcon />,
        disabled: <SwitchDisabledIcon />,
      };
    case 'conditioner':
      return {
        active: <ConditionerIcon />,
        disabled: <ConditionerDisabledIcon />,
      };
    case 'socket':
      return {
        active: <SocketIcon />,
        disabled: <SocketDisabledIcon />,
      };
    case 'lock':
      return {
        active: <LockIcon />,
        disabled: <LockDisabled />,
      };
    case 'curtain':
      return {
        active: <CurtainIcon />,
        disabled: <CurtainDisabledIcon />,
      };
    case 'light':
      return {
        active: <LightIcon />,
        disabled: <LightDisabledIcon />,
      };
    case 'chandelier':
      return {
        active: <ChandelierIcon />,
        disabled: <ChandelierDisabledIcon />,
      };
    case 'floor':
      return {
        active: <FloorIcon />,
        disabled: <FloorDisabledIcon />,
      };

    default:
      return {
        active: <div style={{ fontSize: '1.5rem', fontWeight: 'bolder' }}>?</div>,
        disabled: <div style={{ fontSize: '1.5rem' }}>?</div>,
      };
  }
};
