import { router } from 'App';
import { ROUTES } from 'router/routes';

const NavigationService = {
  getLocationPathName() {
    return window.location.pathname;
  },
  replaceCurrentPathBy(path: string, options = {}) {
    this.navigateToPath(path, { replace: true, ...options });
  },

  navigateToPath(path: string, options = {}) {
    router.navigate(`/${path}`, options);
  },
  navigateToRelativePath(path: string, options = {}) {
    router.navigate(path, { relative: 'path', ...options });
  },

  navigateBack() {
    router.navigate(-1);
  },

  getDefaultRedirectPath(): string {
    // ToDo: implement
    return ROUTES.DASHBOARD.path;
  },
  navigateToDefaultPath() {
    this.navigateToPath(this.getDefaultRedirectPath());
  },
  isLogInPage() {
    const locationPathname = this.getLocationPathName();

    return locationPathname === `/${ROUTES.LOG_IN.path}`;
  },
  navigateToLogOutPage() {
    const redirectPath = ROUTES.LOG_OUT.path;
    this.navigateToPath(redirectPath);
  },

  navigateToLogInPage() {
    const redirectPath = ROUTES.LOG_IN.path;
    this.replaceCurrentPathBy(redirectPath);
  },
};

export default NavigationService;
