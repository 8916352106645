import { ReactElement, useMemo } from 'react';

import { DeviceType } from 'entities/device/model';
import { getDeviceIcons } from './helpers';

export type UseDeviceTemplateHookType = {
  icons: {
    active: ReactElement;
    disabled: ReactElement;
  };
};

export const useDeviceTemplate = (type: DeviceType): UseDeviceTemplateHookType => {
  // useMemo to prevent recalculation each time on Device's change value
  const icons = useMemo(() => getDeviceIcons(type), [type]);

  return {
    icons,
  };
};
