import { createSelector } from '@reduxjs/toolkit';

import { IState } from 'store/types';
import { Device } from './types';

type DevicesByTagSelector = (state: IState, tag?: string) => Device[];

const devicesSelector = (state: IState): Device[] => state.device.devices.data;

export const devicesLoadingSelector = (state: IState): boolean => state.device.devices.isLoading;

const selectTag = (state: IState, tag?: string): string | undefined => tag;
export const makeDevicesByTagSelector = (): DevicesByTagSelector =>
  createSelector([devicesSelector, selectTag], (devices: Device[], tag?: string) => {
    if (!tag) {
      return devices;
    }
    return devices.filter((d) => d.itemTags.has(tag));
  });
