import { createReducer } from '@reduxjs/toolkit';

import { IBoxState } from './types';

import { changeSelectedBox, getBoxesAction } from './actions';

export const boxInitialState: IBoxState = {
  selectedBox: null,
  boxes: {
    isLoading: false,
    data: [],
  },
};

export const boxReducer = createReducer(boxInitialState, (builder) => {
  builder
    .addCase(getBoxesAction.start, (state) => ({
      ...state,
      boxes: {
        ...state.boxes,
        isLoading: true,
      },
    }))
    .addCase(getBoxesAction.success, (state, { payload = [] }) => ({
      ...state,
      selectedBox: payload.length ? payload[0] : null,
      boxes: {
        ...state.boxes,
        isLoading: false,
        data: payload,
      },
    }))
    .addCase(getBoxesAction.failure, (state) => ({
      ...state,
      boxes: {
        ...state.boxes,
        isLoading: false,
      },
    }))
    .addCase(changeSelectedBox, (state, { payload }) => {
      const selectedBox = state.boxes.data.find((box) => box.id === payload);
      if (!selectedBox) return state;
      return {
        ...state,
        selectedBox,
      };
    });
});
