import { useMemo } from 'react';
import UserService from 'services/user/UserService';
import { useSelector } from 'react-redux';

import { tagsDataSelector } from 'entities/tag/model';
import { ROUTES } from 'router/routes';

import { rawItemToItem } from '../helpers/rawItemToItem';
import { tagToDeviceSubNavItem } from '../helpers/tagToSubItem';
import { rawNavItems } from '../../model/items';
import { NavItem } from '../../model/types';

type UseSidebarItemsHook = {
  navItems: NavItem[];
};

export const useSidebarItems = (): UseSidebarItemsHook => {
  const tags = useSelector(tagsDataSelector);
  const deviceSubNamItems = tags.map(tagToDeviceSubNavItem);

  const navItems = useMemo(() => {
    return rawNavItems.reduce<NavItem[]>((acc, item) => {
      const hasAccess = UserService.isHasAccess(item.requiredRole);

      if (!hasAccess) {
        return acc;
      }

      const navItem = rawItemToItem(item);

      if (item.key === ROUTES.DEVICES.path && deviceSubNamItems.length) {
        navItem.children = deviceSubNamItems;
      }

      return [...acc, navItem];
    }, []);
  }, [deviceSubNamItems]);

  return { navItems };
};
