import { ReactElement } from 'react';

import { useItem } from 'entities/item/hooks';

type Props = {
  itemId: UniqueId;
};
function ItemStatus({ itemId }: Props): ReactElement {
  const { itemStatus } = useItem(itemId);

  return <span>{itemStatus}</span>;
}

export default ItemStatus;
