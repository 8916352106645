import React from 'react';
import { ConfigProvider } from 'antd';
import { useSelector } from 'react-redux';

import { ISOLanguageCodes } from 'constants/language';
import { selectedLanguageCodeSelector } from 'store/ui/selectors';

import enUS from 'antd/lib/locale/en_US';
import ruRU from 'antd/lib/locale/ru_RU';

import DEFAULT_THEME from './UIConfigProvider.themes';

interface IUIConfigProviderProps {
  children: React.ReactNode;
}

const UIConfigProvider: React.FC<IUIConfigProviderProps> = ({ children }) => {
  const selectedLanguageCode: ISOLanguageCodes = useSelector(selectedLanguageCodeSelector);

  const languagesMap = {
    [ISOLanguageCodes.ENGLISH]: enUS,
    [ISOLanguageCodes.RUSSIAN]: ruRU,
  };

  return (
    <ConfigProvider locale={languagesMap[selectedLanguageCode]} theme={DEFAULT_THEME}>
      {children}
    </ConfigProvider>
  );
};

export default UIConfigProvider;
