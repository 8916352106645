import { Fragment, ReactNode } from 'react';
import { Translation } from 'react-i18next';

import { getIsSwitchActive } from 'shared/lib';
import { Item } from 'entities/item/model';

export const getItemStatus = (item: Item): ReactNode => {
  switch (item.type) {
    case 'switch':
      return (
        <Translation>{(t) => <Fragment>{t(getIsSwitchActive(item.value) ? 'UI:on' : 'UI:off')}</Fragment>}</Translation>
      );
    case 'text':
      return item.value;
    case 'number':
      // It was formattedValue previously,
      // but we should update and READ only "value" prop - other props should be calculable
      return item.value;
    default:
      return undefined;
  }
};
