import { useDispatch, useSelector } from 'react-redux';
import { ReactNode, useCallback, useMemo } from 'react';

import { IState } from 'store/types';
import { selectedBoxSelector } from 'entities/box/model';

import { Item, makeItemByIdSelector, putItemAction } from '../model';
import { getIsItemActive } from '../lib/helpers/getIsItemActive';
import { getItemStatus } from '../lib/helpers/getItemStatus';

export type UseItemHookType = {
  item: Item;
  isItemActive: boolean;
  itemStatus?: ReactNode;
  onValueChange: (value: string) => void;
};

export const useItem = (itemId: UniqueId): UseItemHookType => {
  const dispatch = useDispatch();
  const box = useSelector(selectedBoxSelector);

  // Create a new memoized selector, for each component instance, on mount
  const itemByIdSelector = useMemo(makeItemByIdSelector, []);
  const item = useSelector((state: IState) => itemByIdSelector(state, itemId));
  const isItemActive = getIsItemActive(item);
  const itemStatus = getItemStatus(item);

  const onValueChange = useCallback(
    (value: string): void => {
      if (box?.id) {
        dispatch(putItemAction.start({ boxId: box.id, itemId, command: value }));
      }
    },
    [box?.id, itemId, dispatch],
  );

  return { item, isItemActive, itemStatus, onValueChange };
};
