import { createReducer } from '@reduxjs/toolkit';

import { getEpochTimeStamp } from 'shared/lib';

import { IRuleState } from './types';

import { getRulesAction, putRuleAction } from './actions';

export const ruleInitialState: IRuleState = {
  rules: {
    isLoading: false,
    data: [],
  },
};

export const ruleReducer = createReducer(ruleInitialState, (builder) => {
  builder
    .addCase(getRulesAction.start, (state) => ({
      ...state,
      rules: {
        ...state.rules,
        isLoading: true,
      },
    }))
    .addCase(getRulesAction.success, (state, action) => ({
      ...state,
      rules: {
        ...state.rules,
        data: action.payload,
        isLoading: false,
      },
    }))
    .addCase(getRulesAction.failure, (state) => ({
      ...state,
      rules: {
        ...state.rules,
        isLoading: false,
      },
    }))

    .addCase(putRuleAction.success, (state) => ({
      ...state,
      rules: {
        ...state.rules,
        lastPatchTimestamp: getEpochTimeStamp(),
      },
    }));
});
