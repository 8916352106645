import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Layout } from 'antd';
import { useSelector } from 'react-redux';

import { ReactComponent as HomeIcon } from 'assets/home.svg';

import { selectedBoxSelector } from 'entities/box/model';

import UserMenu from './userMenu/UserMenu';

import styles from './Header.module.scss';

function Header(): ReactElement {
  const { t } = useTranslation('UI');

  const currentBox = useSelector(selectedBoxSelector);

  return (
    <Layout.Header className={styles.header} data-testid="header">
      <section className={styles.heading}>
        <HomeIcon className={styles.icon} />
        <h3>{currentBox?.name ?? t('Header.heading.placeholder')}</h3>
      </section>
      <section>
        <UserMenu />
      </section>
    </Layout.Header>
  );
}

export default Header;
