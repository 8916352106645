import { combineReducers, createReducer } from '@reduxjs/toolkit';

import { uiInitialState, uiReducer } from 'store/ui/reducer';
import { authInitialState, authReducer } from 'store/auth/reducer';
import { userInitialState, userReducer } from 'store/user/reducer';
import { boxInitialState, boxReducer } from 'entities/box/model';
import { itemInitialState, itemReducer } from 'entities/item/model';
import { deviceInitialState, deviceReducer } from 'entities/device/model';
import { ruleInitialState, ruleReducer } from 'entities/rule/model';
import { tagInitialState, tagReducer } from 'entities/tag/model';

import { IState } from 'store/types';
import * as rootActions from 'store/rootActions';

export const appReducer = combineReducers({
  ui: uiReducer,
  auth: authReducer,
  user: userReducer,
  box: boxReducer,
  item: itemReducer,
  device: deviceReducer,
  rule: ruleReducer,
  tag: tagReducer,
});

export const initialState: IState = {
  ui: uiInitialState,
  auth: authInitialState,
  user: userInitialState,
  box: boxInitialState,
  item: itemInitialState,
  device: deviceInitialState,
  rule: ruleInitialState,
  tag: tagInitialState,
};

export const rootReducer = createReducer(initialState, (builder) => {
  builder.addCase(rootActions.resetState, () => initialState).addDefaultCase(appReducer);
});
