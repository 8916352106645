import { fork } from 'redux-saga/effects';

import { authSagas } from 'store/auth/sagas';
import { userSagas } from 'store/user/sagas';
import { boxSagas } from 'entities/box/model';
import { itemSagas } from 'entities/item/model';
import { deviceSagas } from 'entities/device/model';
import { ruleSagas } from 'entities/rule/model';
import { tagSagas } from 'entities/tag/model';

export default function* rootSaga(): Generator {
  yield fork(authSagas);
  yield fork(userSagas);
  yield fork(boxSagas);
  yield fork(itemSagas);
  yield fork(deviceSagas);
  yield fork(ruleSagas);
  yield fork(tagSagas);
}
