import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { logOut } from 'store/auth/actions';

function LogOutPage(): null {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(logOut.start());
  }, [dispatch]);

  return null;
}

export default LogOutPage;
