import { useDispatch, useSelector } from 'react-redux';
import { MenuProps } from 'antd';
import { useTranslation } from 'react-i18next';

import { logOut } from 'store/auth/actions';

import { boxesSelector } from 'entities/box/model';
import { changeSelectedBox } from 'entities/box/model/actions';

export const useMenuItems = (): MenuProps['items'] => {
  const { t } = useTranslation('UI');

  const dispatch = useDispatch();

  const boxes = useSelector(boxesSelector);

  return [
    {
      key: 'boxes',
      type: 'group',
      label: t('Header.userMenu.boxes.label'),
      children: boxes.map((box) => ({
        key: box.id,
        label: box.name,
        onClick: () => dispatch(changeSelectedBox(box.id)),
      })),
    },
    {
      type: 'divider',
    },
    {
      key: 'logout',
      label: t('Header.userMenu.logout.label'),
      onClick: () => dispatch(logOut.start()),
    },
  ];
};
