import { Item, ItemDTO } from 'entities/item/model';

const mapItemDTO = (dto: ItemDTO): Item => {
  return {
    // todo: name -> id once BE implement it
    id: dto.name,
    name: dto.name,
    type: dto.type,
    humanName: dto.human_name,
    value: dto.value,
    formattedValue: dto.formatted_value,
    tags: dto.tags || [],
    isValueUpdateAllowed: dto.ui,
  };
};

// for Redux Store:
export const mapItemsDTO = (itemsDTO: ItemDTO[]): Record<UniqueId, Item> => {
  const res = <Record<UniqueId, Item>>{};

  itemsDTO.forEach((itemDTO) => {
    res[itemDTO.name] = mapItemDTO(itemDTO);
  });

  return res;
};
