import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { logIn } from 'store/auth/actions';
import { logInIsLoadingSelector } from 'store/auth/selectors';

type HandleLoginParams = { login: string; password: string };

export const useLogin = (): {
  isLoading: boolean;
  handleLogin: (credentials: HandleLoginParams) => void;
} => {
  const dispatch = useDispatch();

  const isLoading = useSelector(logInIsLoadingSelector);

  const handleLogin = useCallback(
    (credentials: HandleLoginParams): void => {
      dispatch(logIn.start(credentials));
    },
    [dispatch],
  );

  return {
    isLoading,
    handleLogin,
  };
};
