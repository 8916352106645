import { all, takeLatest, put, PutEffect, CallEffect } from 'redux-saga/effects';

import * as userActions from 'store/user/actions';

export function* exampleSaga(
  action: ReturnType<typeof userActions.exampleAction.start>,
): Generator<PutEffect | CallEffect, void, unknown> {
  const { payload } = action;

  yield put(userActions.exampleAction.success({ example: payload.exampleData }));
}

export function* userSagas(): Generator {
  yield all([yield takeLatest(userActions.exampleAction.start, exampleSaga)]);
}
