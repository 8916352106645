import { all, takeLatest, call, put, PutEffect, CallEffect } from 'redux-saga/effects';

import { api } from 'services/api/ApiService';
import { apiAccessTokenName } from 'services/api/apiTools';
import * as authRequests from 'services/api/user/requests';
import { GetAuthenticatedUserInfoResponse, LogInResponse } from 'services/api/user/types';

import LocalStorageService from 'services/localStorage/LocalStorageService';

import NavigationService from 'services/navigation/NavigationService';

import { getAuthenticatedUser, logIn, logOut } from 'store/auth/actions';
import { resetState } from 'store/rootActions';

export function* getAuthenticatedUserSaga(): Generator<PutEffect | CallEffect, void, GetAuthenticatedUserInfoResponse> {
  let responseData: GetAuthenticatedUserInfoResponse;

  try {
    responseData = yield call(authRequests.getAuthenticatedUserInfo);
  } catch (error) {
    yield put(getAuthenticatedUser.failure());
    return;
  }

  yield put(getAuthenticatedUser.success(responseData));
}

export function* logInSaga(
  action: ReturnType<typeof logIn.start>,
): Generator<PutEffect | CallEffect, void, LogInResponse> {
  const credentials = action.payload;

  let responseData: LogInResponse;

  try {
    responseData = yield call(authRequests.logIn, credentials);
  } catch (error: unknown) {
    yield put(logIn.failure());
    return;
  }

  const { accessToken, refreshToken } = responseData;

  api.saveTokens(accessToken, refreshToken);

  yield put(
    logIn.success({
      isAuthorized: Boolean(accessToken && refreshToken),
    }),
  );
  yield put(getAuthenticatedUser.start());
}

export function* logOutSaga(): Generator {
  api.deleteTokens();

  yield put(resetState());
  yield put(logOut.success());

  NavigationService.navigateToLogInPage();
}

function restoreSession(): Generator | undefined {
  const token = LocalStorageService.getItem(apiAccessTokenName);
  if (!token) {
    return;
  }
  api.updateAuthorizationHeader(token);
}

export function* authSagas(): Generator {
  yield all([
    yield takeLatest(getAuthenticatedUser.start, getAuthenticatedUserSaga),
    yield takeLatest(logIn.start, logInSaga),
    yield takeLatest(logOut.start, logOutSaga),
    call(restoreSession),
  ]);
}
