import { Device, DeviceDTO } from 'entities/device/model';
import { sortByName } from 'shared/lib';
import { getPrimaryDTOItem } from 'entities/item/lib';
import { Tag } from 'entities/tag/model';

const mapDeviceDTO = (dto: DeviceDTO): Device => {
  const primaryItem = getPrimaryDTOItem(dto.items);

  const { itemIds, itemTags } = dto.items.reduce<{ itemIds: UniqueId[]; itemTags: Set<Tag> }>(
    (acc, item) => {
      const tags = item.tags || [];

      // todo: item.name -> id once BE implement it
      acc.itemIds = [...acc.itemIds, item.name];
      tags.forEach(acc.itemTags.add, acc.itemTags);

      return acc;
    },
    { itemIds: [], itemTags: new Set() },
  );

  return {
    id: dto.id,
    name: dto.name,
    type: dto.type,
    primaryItemType: primaryItem?.type,
    // todo: item.name -> id once BE implement it
    primaryItemId: primaryItem?.name,
    itemIds,
    itemTags,
  };
};

// for Redux Store:
export const mapDevicesDTO = (deviceDTOs: DeviceDTO[]): Device[] => sortByName(deviceDTOs.map(mapDeviceDTO));
