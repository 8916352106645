import { ReactElement } from 'react';
import { SwitchChangeEventHandler } from 'antd/lib/switch';
import { Switch as AntSwitch } from 'antd';

import { switchValues } from 'shared/consts';

import { useItem } from 'entities/item/hooks';

import DeviceCardTemplate from '../deviceCardTemplate/DeviceCardTemplate';
import { DeviceCardTProps } from '../types';

function DeviceCardSwitch({ device }: DeviceCardTProps): ReactElement {
  const { item: primaryItem, isItemActive, itemStatus, onValueChange } = useItem(device.primaryItemId);

  const handleSwitch: SwitchChangeEventHandler = (checked, event) => {
    event.stopPropagation();

    onValueChange(checked ? switchValues.ON : switchValues.OFF);
  };

  return (
    <DeviceCardTemplate
      device={device}
      isCardDisabled={!isItemActive}
      switcher={
        primaryItem.isValueUpdateAllowed ? <AntSwitch onChange={handleSwitch} checked={isItemActive} /> : undefined
      }
      primaryItemId={device.primaryItemId}
      primaryItemStatus={itemStatus}
    />
  );
}

// no need to use memo here, because device usually not changed, and the component is watching itself for item change
export default DeviceCardSwitch;
