import React, { ReactElement } from 'react';

import styles from './DevModePage.module.scss';

function DevModePage(): ReactElement {
  return (
    <div className={styles.rootWrapper} data-testid="dev-mode-page">
      DevModePage
    </div>
  );
}

export default DevModePage;
